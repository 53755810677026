import request from '@/utils/request'

//外财富榜
export function saloonWealthList(data){
  return request({
    url:'/web/rank/wealthList',
    method:'post',
    data
  })
}
//外魅力榜
export function saloonCharismaList(data){
  return request({
    url:'/web/rank/charmList',
    method:'post',
    data
  })
}
//外房间榜
export function roomConsumeList(data){
  return request({
    url:'/web/rank/roomList',
    method:'post',
    data
  })
}
// 查看我的排名
export function checkRank(data){
  return request({
    url:'/V2/Rank/checkRank',
    method:'post',
    data
  })
}

//房间内财富榜
export function roomWealthList(data){
  return request({
    url:'/web/roomRank/wealthList',
    method:'post',
    data
  })
}

//房间内魅力榜
export function roomCharismaList(data){
  return request({
    url:'/web/roomRank/charmList',
    method:'post',
    data
  })
}
//房间内惊喜榜
export function surpriseList(data){
  return request({
    url:'/web/roomRank/surpriseList',
    method:'post',
    data
  })
}

//房间内幸运榜
export function luckyList(data){
  return request({
    url:'/web/roomRank/luckyList',
    method:'post',
    data
  })
}
//开黑榜
export function gameList(data){
  return request({
    url:'/web/game/orderRank',
    method:'post',
    data
  })
}



//人气榜单
export function popularList(data){
  return request({
    url:'/web/rank/popularList',
    method:'post',
    data
  })
}

//你说我猜积分榜展示
export function playGuessDisplay(data){
  return request({
    url:'/V2/public/playGuessDisplay',
    method:'post',
    data
  })
}

//你说我猜积分榜展示列表
export function playGuessList(data){
  return request({
    url:'/V2/public/playGuessList',
    method:'post',
    data
  })
}

// 宝箱礼物
export function boxGift(data){
  return request({
    url:'/V2/GoldEggs/boxGift',
    method:'post',
    data
  })
}

// 拆出记录
export function openBoxRecord(data){
  return request({
    url:'/web/eggs/eggsLog',
    method:'post',
    data
  })
}

//宝箱礼物展示
export function prizeDisplay(data){
  return request({
    url:'/web/eggs/prizeList',
    method:'post',
    data
  })
}

//宝箱手气榜
export function boxLuckyRank(data){
  return request({
    url:'/web/eggs/luckList',
    method:'post',
    data
  })
}

//开宝箱排行
export function goldEggsList(data){
  return request({
    url:'/web/eggs/eggsList',
    method:'post',
    data
  })
}

//花花排行榜
export function welFareRank(data){
  return request({
    url:'/web/grow/growRank',
    method:'post',
    data
  })
}
//开黑竞技榜
export function gameRankList(data){
  return request({
    url:'/web/activity/rankList',
    method:'post',
    data
  })
}
//星火燎原榜
export function gameFireRankList(data){
  return request({
    url:'/web/pk/pkAct',
    method:'get',
    data
  })
}
//每周幸运儿首页
export function indexData(data){
  return request({
    url:'/web/activity/luckyWeekIndex',
    method:'post',
    data
  })
}
//抽奖
export function GoLottery(data){
  return request({
    url:'/web/activity/luckyWeekDraw',
    method:'post',
    data
  })
}
//我的记录
export function MyRecord(data){
  return request({
    url:'web/activity/myLuckyWeekRecord',
    method:'post',
    data
  })
}
//排行榜
export function luckyMan(data){
  return request({
    url:'/web/activity/luckyWeekRank',
    method:'post',
    data
  })
}
//派对周星首页
export function weekPartyIndex(data){
  return request({
    url:'/web/activity/carnivalIndex',
    method:'post',
    data
  })
}
//我的记录
export function weekPartyRecord(data){
  return request({
    url:'web/activity/carnivalRecord',
    method:'post',
    data
  })
}
//排队周星排名
export function weekPartyRank(data){
  return request({
    url:'web/activity/carnivalRank',
    method:'post',
    data
  })
}
//派对周星抽奖
export function weekPartyDraw(data){
  return request({
    url:'/web/activity/carnivalDraw',
    method:'post',
    data
  })
}
//守护榜/宠爱榜
export function guardRank(data){
  return request({
    url:'/web/intimacy/guardRank',
    method:'get',
    data
  })
}
//cp榜
export function cpRank(data){
  return request({
    url:'/web/intimacy/cpRank',
    method:'get',
    data
  })
}
//我的CP
export function myCpRank(data){
  return request({
    url:'/web/intimacy/myCpRank',
    method:'get',
    data
  })
}
//选择CP展示
export function selectCp(data){
  return request({
    url:'/web/intimacy/selectCp',
    method:'post',
    data
  })
}