<template>
  <div :style="{ backgroundImage: 'url(' + ranklist[rankCur].topbg + ')' }"
    style="background-size:100% 100%; height:calc(100vh - 120px); background-color:#fff;background-repeat: no-repeat;overflow-y: scroll;padding-bottom: 120px;">
    <!-- <back-nav class="title_top" :icon="'img/backw.png'" :textColor="'#fff'" :backColor="'none'" /> -->
    <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
      <div class="top" style="margin:0;">
        <!-- <div class="topback" @click="closePage"></div> -->
        <div class="topNav">
          <div class="back" style="background-image: url('img/backw.png');" @click="closePage"></div>
          <div class="titem display_flex justify-content_flex-center align-items_flex-end"
            v-for="(item, index) in ranklist" :key="index" @click="rankchange(index, item.list_type)">
            <div class="tab"
              :style="rankCur == index ? 'background-image:url(' + item.active_bg + ')' : 'background-image:url(' + item.default_bg + ')'">
            </div>
            <!-- <i v-if="rankCur==index" class="tactive"></i> -->
          </div>
        </div>
        <div style="display: flex;justify-content: flex-end;">
          <div class="radiotype display_flex align-items_center" v-if="rankCur != 4">
          <div class="rtitem" :class="{ 'rtName': ranklist[rankCur].cur == index }"
            v-for="(item, index) in ranklist[rankCur].type" :key="index" @click="typechange(index, item.sort_date)">
            {{ item.name }}</div>
        </div>
        </div>
   
        <!-- 开黑榜tab切换组件 -->
        <div class="tab-box" v-if="rankCur == 4">
          <div class="left_tab">
            <div class="left_tab_left"
              :style="type == 1 ? 'background-image: url(img/radio/zudui_click.png);' : 'background-image: url(img/radio/zudui_default.png);'"
              @click="leftTabChange"></div>
            <div class="left_tab_right"
              :style="type == 2 ? 'background-image: url(img/radio/dashen_click.png);' : 'background-image: url(img/radio/dashen_default.png);'"
              @click="leftTabChange"></div>
          </div>
          <!-- <div class="left-tab" :style="type==1?'background-image:url(img/gameRank/zd_click.png)':'background-image:url(img/gameRank/ds_click.png)'" @click="leftTabChange"></div> -->
          <div class="right-tab"
            :style="data_type == 'day' ? 'background-image:url(img/radio/day_rank_click.png)' : 'background-image:url(img/radio/week_rank_click.png)'"
            @click="rightTabChange"></div>

        </div>
        <div v-if="nodata" class="noData">
          <img src="img/noData.png" alt="">
        </div>
        <!-- 前4个榜单 -->
        <div>
          <div class="box-container display_flex justify-content_flex-center">
            <div class="box-top2 display_flex flex-direction_column align-items_center"
              v-if="topThreeItems.length >= 2"
              @click="checkUser(topThreeItems[1].user_id)">
              <div class="box-head2" style="background-image:url(img/radio/top_2.png)">
                <img class="box-img" style=" border: 2px solid #e5faff;" :src="topThreeItems[1].head_portrait" alt="">

                <!-- <img class="box-bgimg2" src="img/no2/no.png" alt=""> -->
              </div>
              <div class="box-news">
                <template>
                  <div class="box-name">{{ topThreeItems[1].nickname }}</div>
                </template>
                <!-- <div style="display: flex;justify-content: center;">
                  <div class="box-level" v-if="rankCur == 0 || rankCur == 1"
                    :style="{ backgroundImage: 'url(' + 'img/level/level' + rankCur + '/' + topThreeItems[1].user_level + '.png' + ')' }">
                  </div>
                </div> -->
                <div style="margin-top: 9px;text-align: center;">
                  <span style="color:#ffffff;opacity: .7;">距上一名</span><span style="color: #ffed38;font-weight: 700;">{{
                    topThreeItems[1].differ_value }}</span>
                </div>

              </div>
              <div class="box-bottom display_flex justify-content_flex-center align-items_center">
                <template>
                  <!-- <div v-if="rankCur == 3" class="box-num2">{{ topThreeItems[1].value }}</div> -->
                  <!-- <div class="box-num2">{{ topThreeItems[1].differ_value }}</div> -->
                </template>

              </div>
            </div>
            <div class="box-top1 display_flex flex-direction_column align-items_center"
               v-if="topThreeItems.length >= 1"
              @click="checkUser(topThreeItems[0].user_id)">
              <div class="box-head1" style="background-image:url(img/radio/top_1.png)">
                <img class="box-img" :src="topThreeItems[0].head_portrait" alt="">
                <!-- <img class="box-bgimg1" src="img/no1/no.png" alt=""> -->
              </div>
              <div class="box-news">
                <template>
                  <div class="box-name">{{ topThreeItems[0].nickname }}</div>
                </template>
                <!-- <div style="display: flex;justify-content: center;">
                  <div class="box-level" v-if="rankCur == 0 || rankCur == 1"
                    :style="{ backgroundImage: 'url(' + 'img/level/level' + rankCur + '/' + topThreeItems[0].user_level + '.png' + ')' }">
                  </div>
                </div> -->

              </div>
              <div class="box-bottom display_flex justify-content_flex-center align-items_center">
                <!-- <i class="box-num1"></i> -->
                <template>
                  <span class="box-num2">******</span>
                </template>
              </div>
            </div>
            <div class="box-top3 display_flex flex-direction_column align-items_center" v-if="topThreeItems.length >= 3"
              @click="checkUser(topThreeItems[2].user_id)">
              <div class="box-head2" style="background-image:url(img/radio/top_2.png)">
                <img class="box-img" style=" border: 2px solid #ffede0;" :src="topThreeItems[2].head_portrait" alt="">
                <!-- <img class="box-bgimg2" src="img/no3/no.png" alt=""> -->
              </div>
              <div class="box-news">
                <template>
                  <div class="box-name">{{ topThreeItems[2].nickname }}</div>
                </template>
                <!-- <div style="display: flex;justify-content: center;">
                  <div class="box-level" v-if="rankCur == 0 || rankCur == 1"
                    :style="{ backgroundImage: 'url(' + 'img/level/level' + rankCur + '/' + topThreeItems[2].user_level + '.png' + ')' }">
                  </div>
                </div> -->
                <div style="margin-top: 9px;text-align: center;">
                  <span style="color:#ffffff;opacity: .7;">距上一名</span><span style="color: #ffed38;font-weight: 700;">{{
                    topThreeItems[2].differ_value }}</span>
                </div>
              </div>
              <!-- <div v-if="topThreeItems[2].differ_display == 2"
                class="box-bottom display_flex justify-content_flex-center align-items_center">
                <i v-if="rankCur != 3" class="box-num1"></i>
                <template>
                  <span v-if="rankCur == 3" class="box-num2">{{ topThreeItems[2].value }}</span>
                  <span v-else class="box-num2">{{ topThreeItems[2].differ_value }}</span>
                </template>
              </div> -->
            </div>
            <div class="podium" style="background-image: url('img/radio/top_three_bg.png');"></div>
          </div>
          <!-- <div class="myRank" v-if="(rankCur == 0 || rankCur == 1) && my_rank<101 && my_rank>0" @click="clickMy">去看看我的排行吧>></div> -->
          <!-- <div class="myRank" v-if="(rankCur != 2) && my_rank.length>0">我的排行：暂未上榜</div> -->
          <!-- <div class="myRank" v-if="(rankCur != 2) && (my_rank > 100 || my_rank == 0)">暂未上榜</div> -->
        </div>
      </div>
      <!-- 前4个榜单 -->
      <div>
        <div class="list-scroll" style="background-image: url('img/radio/list_bg.png');">
          <template v-if="listItems.length >= 0">
            <div class="item display_flex justify-content_flex-justify align-items_center"
              v-for="(item, index) in listItems" :key="index">
              <div class="display_flex align-items_center" @click="checkUser(item.user_id)">
                <div class="item-order">{{ index + 4 }}</div>
                <img class="item-img" :src="item.head_portrait" alt="">
                <div class="display_flex align-items_center">
                  <template>
                    <div class="item-name">{{ item.nickname }}</div>
                  </template>
                  <div class="item-level" v-if="rankCur == 0 || rankCur == 1"
                    :style="{ backgroundImage: 'url(' + 'img/level/level' + rankCur + '/' + item.user_level + '.png' + ')' }">
                  </div>
                </div>
              </div>
              <div class="item-right">
                <div class="display_flex align-items_center">
                  <span style="color: #999999;">距上一名</span>
                </div>
                <template>
                  <p style="color:#9664FF">{{ item.differ_value }}</p>
                </template>
              </div>
            </div>
          </template>
        </div>
      </div>
    </van-pull-refresh>
    <div>
      <div class="hover_box" style="background-image: url('img/radio/hover_bg.png');" v-if="my_rank.length != 0">
        <div class="display_flex align-items_center" @click="checkUser(my_rank.user_id)">
          <img class="item-img" :src="my_rank.my_head_portrait" alt="">
          <div class="display_flex align-items_center">
            <template>
              <div class="item-name">{{ my_rank.my_nickname }}</div>
            </template>
            <div class="item-level" v-if="rankCur == 0 || rankCur == 1"
              :style="{ backgroundImage: 'url(' + 'img/level/level' + rankCur + '/' + my_rank.my_level + '.png' + ')' }">
            </div>
          </div>
        </div>
        <div class="item-right">
          <div class="display_flex align-items_center">
            <span style="color: #111;opacity: .7;margin-right: 15px;font-size: 14px;">暂未上榜</span>
          </div>
        </div>
      </div>
    </div>

    <!-- <div v-if="rankCur == 4 && is_up == 0">
      <div class="hover_box" style="background-image: url('img/radio/hover_bg.png');">
        <div class="display_flex align-items_center">
          <img class="item-img" :src="my_avatar" alt="">
          <div class="display_flex align-items_center">
            <template>
              <div class="item-name">{{ my_nickname }}</div>
            </template>
 
            <img class="tou-xian" :src="forPic(my_level)" alt="">
     
          </div>
        </div>
        <div class="item-right">
          <span style="color: #ffffff;opacity: .7;display: inline;margin-right: 15px;">距上一名还差 <span
              style="color:#ffffff;opacity: .7;display: inline;">{{ my_differ_value }}</span> 单</span>
        </div>
      </div>
    </div> -->

  </div>
</template>

<script>
import "@/assets/css/rank.css"
import BackNav from "@/components/backNav.vue";
import { saloonWealthList, saloonCharismaList, roomConsumeList, checkRank, popularList, gameList } from "@/api/ranking";
export default {
  name: 'radio',
  components: {
    BackNav,
  },
  data() {
    return {
      data_type: 'day',
      type: 1,
      is_up: -1,
      my_differ_value: 0,
      my_level: 0,
      my_nickname: '',
      my_avatar: '',
      rankCur: 0,
      ranklist: [
        {
          name: '财富榜',
          type: [
            {
              name: '周榜',
              sort_date: 'week'
            },
            {
              name: '月榜',
              sort_date: 'month'
            }
          ],
          cur: 0,
          topbg: 'img/radio/01.png',
          list_type: 'rich',
          active_bg: 'img/radio/caifu_active.png',
          default_bg: 'img/radio/caifu_default.png',
          tab_click: 'img/radio/small_week_rank_click.png',
          tab_default: 'img/radio/small_month_rank_click.png'

        }, {
          name: '魅力榜',
          type: [
            {
              name: '周榜',
              sort_date: 'week'
            },
            {
              name: '月榜',
              sort_date: 'month'
            }
          ],
          cur: 0,
          topbg: 'img/radio/02.png',
          list_type: 'charm',
          active_bg: 'img/radio/meili_active.png',
          default_bg: 'img/radio/meili_default.png',
          tab_click: 'img/radio/small_week_rank_click.png',
          tab_default: 'img/radio/small_month_rank_click.png'
        }, {
          name: '房间榜',
          type: [
            {
              name: '日榜',
              sort_date: 'day'
            },
            {
              name: '周榜',
              sort_date: 'week'
            }
          ],
          cur: 0,
          topbg: 'img/radio/03.png',
          list_type: 'rooms',
          active_bg: 'img/radio/room_active.png',
          default_bg: 'img/radio/room_default.png',
          tab_click: 'img/radio/day_rank_click.png',
          tab_default: 'img/radio/week_rank_click.png'
        },
        {
          name: '人气榜',
          type: [
            {
              name: '日榜',
              sort_date: 'day'
            },
            {
              name: '周榜',
              sort_date: 'week'
            }
          ],
          cur: 0,
          topbg: 'img/radio/04.png',
          list_type: 'mood',
          active_bg: 'img/radio/renqi_active.png',
          default_bg: 'img/radio/renqi_default.png',
          tab_click: 'img/radio/day_rank_click.png',
          tab_default: 'img/radio/week_rank_click.png'
        },
        {
          name: '开黑榜',
          type: [
            {
              name: '日榜',
              sort_date: 'day'
            },
            {
              name: '周榜',
              sort_date: 'week'
            }
          ],
          cur: 0,
          topbg: 'img/radio/05.png',
          list_type: 'game',
          active_bg: 'img/radio/game_active.png',
          default_bg: 'img//radio/game_default.png',
          tab_click: 'img/radio/zudui_click.png',
          tab_default: 'img/radio/dashen_click.png'
        }
      ],
      sort_date: 'week',
      topThreeItems: [],
      listItems: [],
      my_rank: '',
      showMyrank: false,
      showPay: false,
      isLoading: false,
      nodata: false,
    }
  },
  computed: {
    mytype() {
      let rankCur = this.rankCur
      if (rankCur == 0) {
        if (this.ranklist[rankCur].cur == 0) {
          return 'saloonWealthWeekList'
        } else {
          return 'saloonWealthAllList'
        }
      }
      if (rankCur == 1) {
        if (this.ranklist[rankCur].cur == 0) {
          return 'saloonCharismaWeekList'
        } else {
          return 'saloonCharismaAllList'
        }
      }
    }
  },
  created() {
    let access_token = this.$route.query.access_token
    localStorage.setItem('access_token', access_token)
    this.getData()
  },
  methods: {
    onRefresh() {
      setTimeout(() => {
        this.$toast('刷新成功')
        this.isLoading = false;
        this.getData()
      }, 1000);
    },
    //榜单切换
    rankchange(e) {
      // console.log(e)
      this.rankCur = e
      if (this.rankCur == 0 || this.rankCur == 1) {
        this.sort_date = 'week'
        this.ranklist[e].cur = 0
      } else {
        this.sort_date = 'day'
        this.ranklist[e].cur = 0
      }
      this.my_rank = ''
      this.topThreeItems = []
      this.listItems = []
      this.getData()
    },
    //周榜总榜切换
    typechange(e, type) {
      console.log(type)
      let rankCur = this.rankCur
      this.ranklist[rankCur].cur = e
      this.sort_date = type
      this.my_rank = ''
      this.topThreeItems = []
      this.listItems = []
      this.getData()
    },
    getData() {
      let Params = {
        version: this.$route.query.version,
        date_type: this.sort_date
        // page: 1
      }
      if (this.rankCur == 0) {
        saloonWealthList(Params).then(response => {
          const topThreeItems = response.data.list.slice(0, 3);
          const listItems = response.data.list.slice(3);
          this.topThreeItems = topThreeItems
          this.listItems = listItems
          this.my_rank = response.data.my_rank
          if (response.data.list.length == 0) {
            this.nodata = true
          } else {
            this.nodata = false
          }
        },
          error => {
            // console.log(error)
            this.$toast(error)
          }).catch((error) => {
            // console.log(error)
            this.$toast(error)
          })
      }
      if (this.rankCur == 1) {
        saloonCharismaList(Params).then(response => {
          const topThreeItems = response.data.list.slice(0, 3);
          const listItems = response.data.list.slice(3);
          this.topThreeItems = topThreeItems
          this.listItems = listItems
          this.my_rank = response.data.my_rank
          if (response.data.list.length == 0) {
            this.nodata = true
          } else {
            this.nodata = false
          }
        },
          error => {
            this.$toast(error)
          }).catch((error) => {
            this.$toast(error)
          })
      }
      if (this.rankCur == 2) {
        roomConsumeList(Params).then(response => {
          const topThreeItems = response.data.list.slice(0, 3);
          const listItems = response.data.list.slice(3);
          this.topThreeItems = topThreeItems
          this.listItems = listItems
          this.my_rank = response.data.my_rank
          if (response.data.list.length == 0) {
            this.nodata = true
          } else {
            this.nodata = false
          }
        },
          error => {
            this.$toast(error)
          }).catch((error) => {
            this.$toast(error)
          })
      }
      if (this.rankCur == 3) {
        popularList(Params).then(response => {
          const topThreeItems = response.data.list.slice(0, 3);
          const listItems = response.data.list.slice(3);
          this.topThreeItems = topThreeItems
          this.listItems = listItems
          this.my_rank = response.data.my_rank
          if (response.data.list.length == 0) {
            this.nodata = true
          } else {
            this.nodata = false
          }
        },
          error => {
            this.$toast(error)
          }).catch((error) => {
            this.$toast(error)
          })
      }
      if (this.rankCur == 4) {
        let p = {
          date_type: this.data_type,
          order_type: this.type,
          // page: 1
        }
        gameList(p).then(response => {//开黑榜
          const topThreeItems = response.data.list.slice(0, 3);
          const listItems = response.data.list.slice(3);
          this.topThreeItems = topThreeItems
          this.listItems = listItems
          this.is_up = response.data.is_up
          this.my_differ_value = response.data.my_differ_value
          this.my_level = response.data.my_level
          this.my_nickname = response.data.my_nickname
          this.my_avatar = response.data.user_pic
          // this.my_rank = response.data.my_rank
          if (response.data.list.length == 0) {
            this.nodata = true
          } else {
            this.nodata = false
          }
        },
          error => {
            this.$toast(error)
          }).catch((error) => {
            this.$toast(error)
          })
      }
    },
    clickMy() {
      this.showMyrank = true
    },
    gotoPay() {
      let param = {
        access_token: this.$route.query.access_token,
        version: this.$route.query.version,
        type: this.mytype,
      }
      checkRank(param).then(response => {
        console.log(response)
      },
        error => {
          this.$toast(error)
        }).catch((error) => {
          this.$toast(error)
        })
    },
    leftTabChange() {
      console.log(this.type)
      if (this.type == 1) {
        this.type = 2
      } else {
        this.type = 1
        
      }
      this.getData()
    },
    rightTabChange() {
     
      if (this.data_type == 'day') {
        this.data_type = 'week'
      } else {
        this.data_type = 'day'
      }
      console.log(this.data_type)
      this.getData()
    },
    hidePay() {
      this.showPay = false
      this.showMyrank = false
    },
    closePage() {
      let params = '调用'
      this.$bridge.callhandler('closePage', params, (data) => {
        // 处理返回数据
      })
    },
    checkUser(id) {
      let param = id.toString()
      this.$bridge.callhandler('checkUser', param, (data) => {
        // 处理返回数据
      })
    },
    forPic(level) {
      let picUrl
      if (this.type == 1) {
        picUrl = 'img/gameRank/wj_level_' + level + '.png'
      }
      if (this.type == 2) {
        picUrl = 'img/gameRank/ds_level_' + level + '.png'
      }
      return picUrl
    }
  },
}
</script>